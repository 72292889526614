import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayRechargeRecordModel,
  PaginationQuery,
  WorkSchedulingExchangeRate
} from "fp/modules/recharge-record/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayRechargeRecordModel>> {
    return await HttpClient.Get(`/v1/recharge/record/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/recharge/record/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayRechargeRecordModel>> {
    return await HttpClient.Post(`/v1/recharge/record/pagination`, query);
  }
  public static async update(
    model: FMPayRechargeRecordModel
  ): Promise<FMPayResponse<FMPayRechargeRecordModel>> {
    return await HttpClient.Post(`/v1/recharge/record/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayRechargeRecordModel>> {
    return await HttpClient.Post(`/v1/recharge/record/add`, dto);
  }

  public static async exchangeRate(
    workSchedulingOperationId: string
  ): Promise<FMPayResponse<{ [key: string]: WorkSchedulingExchangeRate }>> {
    return await HttpClient.Get(`/v1/recharge/record/exchange/rate/${workSchedulingOperationId}`);
  }
}
