export function filterBlankValues<T extends {}>(data: T): Partial<T> {
  const entries = Object.entries(data);
  const nonEmptyOrNull = entries.filter(([key, val]) => val !== "" && val !== null);
  const output = Object.fromEntries(nonEmptyOrNull);
  return output as Partial<T>;
}

export function formatNumberWithCommas(s: string): string {
  let [integer, decimal = ""] = s.split(".");

  const doSplit = (n: string, isInteger = true) => {
    if (n === "" || n === void 0) {
      return "";
    }

    let a: string[] = n.split("");

    if (isInteger) {
      a = a.reverse();
    }

    let str: string[] = [];

    for (let i = 0; i < a.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        str.push(",");
      }
      str.push(a[i]);
    }

    if (isInteger) {
      return str.reverse().join("");
    }

    return str.join("");
  };

  integer = doSplit(integer);
  decimal = doSplit(decimal, false);
  return integer + (decimal === "" ? "" : "." + decimal);
}

export function deepClone<T extends {}>(value: T): T {
  return <T>JSON.parse(JSON.stringify(value));
}

export function normalize<T extends {}>(data: T): Partial<T> {
  const result: Partial<T> = {};
  for (const propKey in data) {
    if (!Object.prototype.hasOwnProperty.call(data, propKey)) {
      continue;
    }
    const v: any = data[propKey];

    if (v === "" || v === void 0 || v === null || (typeof v === "number" && isNaN(v))) {
      continue;
    }
    result[propKey] = v;
  }
  return result;
}
