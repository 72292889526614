import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/bank-transfer/Service";
import {
  CreateDTO,
  FMPayBankTransferModel,
  PaginationQuery,
  UpdateDTO
} from "fp/modules/bank-transfer/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const bankTransferFindById = createAsyncThunk<FMPayBankTransferModel, string>(
  "bank.transfer/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const bankTransferDeleteByIds = createAsyncThunk<boolean, string[]>(
  "bank.transfer/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const bankTransferActive = createAsyncThunk<FMPayBankTransferModel, string>(
  "bank.transfer/active",
  async (id: string) => {
    const resp = await Service.active(id);
    return resp.data;
  }
);

export const bankTransferDisable = createAsyncThunk<FMPayBankTransferModel, string>(
  "bank.transfer/disable",
  async (id: string) => {
    const resp = await Service.disable(id);
    return resp.data;
  }
);

export const bankTransferUpdate = createAsyncThunk<FMPayBankTransferModel, UpdateDTO>(
  "bank.transfer/update",
  async (model: UpdateDTO) => {
    const resp = await Service.update(model);
    return resp.data;
  }
);

export const bankTransferPagination = createAsyncThunk<
  FMPayPagination<FMPayBankTransferModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("bank.transfer/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const bankTransferCreate = createAsyncThunk<FMPayBankTransferModel, CreateDTO>(
  "bank.transfer/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface BankTransferState {
  model: FMPayBankTransferModel | null;
  models: FMPayPagination<FMPayBankTransferModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    active: string;
    disable: string;
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: BankTransferState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    active: "",
    disable: "",
    update: "",
    create: false
  }
};

export const bankTransferSlice = createSlice({
  name: "bankTransfer",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(bankTransferFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(bankTransferFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(bankTransferFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(bankTransferDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(bankTransferDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(bankTransferDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(bankTransferActive.pending, (state, action) => {
      state.loading.active = action.meta.arg;
    });
    builder.addCase(bankTransferActive.rejected, (state) => {
      state.loading.active = "";
    });
    builder.addCase(bankTransferActive.fulfilled, (state) => {
      state.loading.active = "";
    });

    builder.addCase(bankTransferDisable.pending, (state, action) => {
      state.loading.disable = action.meta.arg;
    });
    builder.addCase(bankTransferDisable.rejected, (state) => {
      state.loading.disable = "";
    });
    builder.addCase(bankTransferDisable.fulfilled, (state) => {
      state.loading.disable = "";
    });

    builder.addCase(bankTransferUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(bankTransferUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(bankTransferUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(bankTransferPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(bankTransferPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(bankTransferPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(bankTransferCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(bankTransferCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(bankTransferCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default bankTransferSlice.reducer;
