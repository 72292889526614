import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/order-relationship/Service";
import {
  CreateDTO,
  FMPayOrderRelationshipModel,
  PaginationQuery
} from "fp/modules/order-relationship/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const orderRelationshipFindById = createAsyncThunk<FMPayOrderRelationshipModel, string>(
  "order.relationship/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const orderRelationshipDeleteByIds = createAsyncThunk<boolean, string[]>(
  "order.relationship/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const orderRelationshipPagination = createAsyncThunk<
  FMPayPagination<FMPayOrderRelationshipModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "order.relationship/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const orderRelationshipUpdate = createAsyncThunk<
  FMPayOrderRelationshipModel,
  FMPayOrderRelationshipModel
>("order.relationship/update", async (model: FMPayOrderRelationshipModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const orderRelationshipCreate = createAsyncThunk<FMPayOrderRelationshipModel, CreateDTO>(
  "order.relationship/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface OrderRelationshipState {
  model: FMPayOrderRelationshipModel | null;
  models: FMPayPagination<FMPayOrderRelationshipModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: OrderRelationshipState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const orderRelationshipSlice = createSlice({
  name: "orderRelationship",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(orderRelationshipFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(orderRelationshipFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(orderRelationshipFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(orderRelationshipDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(orderRelationshipDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(orderRelationshipDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(orderRelationshipUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(orderRelationshipUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(orderRelationshipUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(orderRelationshipPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(orderRelationshipPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(orderRelationshipPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(orderRelationshipCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(orderRelationshipCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(orderRelationshipCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default orderRelationshipSlice.reducer;
