import dayjs, { ConfigType, Dayjs, OptionType } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isBetween from "dayjs/plugin/isBetween";
import { getDayJsTimezone, getUserLocale, setUserLocal } from "fp/store/slice/sign";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayOfYear);
dayjs.extend(isBetween);

const dayjsWrapper = (
  date?: ConfigType,
  format?: OptionType,
  locale?: string,
  strict?: boolean
): Dayjs => {
  return dayjs(date, format, locale, strict).tz(getDayJsTimezone());
};

setUserLocal(getUserLocale());

export default dayjsWrapper;
