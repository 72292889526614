import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/group-account-book-note/Service";
import {
  CreateDTO,
  FMPayGroupAccountBookNoteModel,
  PaginationQuery
} from "fp/modules/group-account-book-note/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const groupAccountBookNoteFindById = createAsyncThunk<
  FMPayGroupAccountBookNoteModel,
  string
>("group.account.book.note/find.by.id", async (id: string) => {
  const resp = await Service.findById(id);
  return resp.data;
});

export const groupAccountBookNoteDeleteByIds = createAsyncThunk<boolean, string[]>(
  "group.account.book.note/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const groupAccountBookNotePagination = createAsyncThunk<
  FMPayPagination<FMPayGroupAccountBookNoteModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "group.account.book.note/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const groupAccountBookNoteUpdate = createAsyncThunk<
  FMPayGroupAccountBookNoteModel,
  FMPayGroupAccountBookNoteModel
>("group.account.book.note/update", async (model: FMPayGroupAccountBookNoteModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const groupAccountBookNoteCreate = createAsyncThunk<
  FMPayGroupAccountBookNoteModel,
  CreateDTO
>("group.account.book.note/create", async (dto: CreateDTO) => {
  const resp = await Service.create(dto);
  return resp.data;
});

export interface GroupAccountBookNoteState {
  model: FMPayGroupAccountBookNoteModel | null;
  models: FMPayPagination<FMPayGroupAccountBookNoteModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: GroupAccountBookNoteState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const groupAccountBookNoteSlice = createSlice({
  name: "groupAccountBookNote",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(groupAccountBookNoteFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(groupAccountBookNoteFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(groupAccountBookNoteFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(groupAccountBookNoteDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(groupAccountBookNoteDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(groupAccountBookNoteDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(groupAccountBookNoteUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(groupAccountBookNoteUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(groupAccountBookNoteUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(groupAccountBookNotePagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(groupAccountBookNotePagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(groupAccountBookNotePagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(groupAccountBookNoteCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(groupAccountBookNoteCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(groupAccountBookNoteCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default groupAccountBookNoteSlice.reducer;
