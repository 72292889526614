import { ReactNode } from "react";
import { Col, Row, Space, Typography } from "antd";

export interface RsFooterProps {}

export function RsFooter(props: RsFooterProps): ReactNode {
  return (
    <Row justify="center" align="middle" style={{ paddingTop: 20 }}>
      <Col>
        <Space size="large">
          <Typography.Text type="secondary">Software Version</Typography.Text>
          <Typography.Text type="secondary">{process.env.REACT_APP_VERSION}</Typography.Text>
        </Space>
      </Col>
    </Row>
  );
}
