import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayOrderRelationshipModel,
  PaginationQuery
} from "fp/modules/order-relationship/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayOrderRelationshipModel>> {
    return await HttpClient.Get(`/v1/order/relationship/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/order/relationship/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayOrderRelationshipModel>> {
    return await HttpClient.Post(`/v1/order/relationship/pagination`, query);
  }
  public static async update(
    model: FMPayOrderRelationshipModel
  ): Promise<FMPayResponse<FMPayOrderRelationshipModel>> {
    return await HttpClient.Post(`/v1/order/relationship/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayOrderRelationshipModel>> {
    return await HttpClient.Post(`/v1/order/relationship/add`, dto);
  }
}
