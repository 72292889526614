import {
  FMPayGroupModel,
  FMPayPagination,
  FMPayPaginationQuery,
  FMPayResponse
} from "fp/store/type";
import { CreateDTO, PaginationQuery } from "fp/modules/group/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayGroupModel>> {
    return await HttpClient.Post("/v1/group/add", dto);
  }

  public static async update(id: string, alias: string[]): Promise<FMPayResponse<FMPayGroupModel>> {
    return await HttpClient.Post("/v1/group/update/alias", { id, alias });
  }

  public static async active(id: string): Promise<FMPayResponse<FMPayGroupModel>> {
    return await HttpClient.Post(`/v1/group/active/${id}`);
  }

  public static async disable(id: string): Promise<FMPayResponse<FMPayGroupModel>> {
    return await HttpClient.Post(`/v1/group/disable/${id}`);
  }

  public static async items(): Promise<FMPayPagination<FMPayGroupModel>> {
    return await HttpClient.Post("/v1/group/items", {});
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayPagination<FMPayGroupModel>> {
    return await HttpClient.Post("/v1/group/pagination", query);
  }
}
