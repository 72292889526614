import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayBankTransferModel,
  PaginationQuery,
  UpdateDTO
} from "fp/modules/bank-transfer/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayBankTransferModel>> {
    return await HttpClient.Get(`/v1/bank/transfer/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/bank/transfer/deleted`, { ids });
  }

  public static async active(id: string): Promise<FMPayResponse<FMPayBankTransferModel>> {
    return await HttpClient.Post(`/v1/bank/transfer/active/${id}`);
  }

  public static async disable(id: string): Promise<FMPayResponse<FMPayBankTransferModel>> {
    return await HttpClient.Post(`/v1/bank/transfer/disable/${id}`);
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayBankTransferModel>> {
    return await HttpClient.Post(`/v1/bank/transfer/pagination`, query);
  }

  public static async update(model: UpdateDTO): Promise<FMPayResponse<FMPayBankTransferModel>> {
    return await HttpClient.Post(`/v1/bank/transfer/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayBankTransferModel>> {
    return await HttpClient.Post(`/v1/bank/transfer/add`, dto);
  }
}
