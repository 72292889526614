import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import { CreateDTO, FMPayDictionaryModel, PaginationQuery } from "fp/modules/dictionary/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayDictionaryModel>> {
    return await HttpClient.Get(`/v1//system/dictionary/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/system/dictionary/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayDictionaryModel>> {
    return await HttpClient.Post(`/v1/system/dictionary/pagination`, query);
  }

  public static async update(
    model: FMPayDictionaryModel
  ): Promise<FMPayResponse<FMPayDictionaryModel>> {
    return await HttpClient.Post(`/v1/system/dictionary/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayDictionaryModel>> {
    return await HttpClient.Post(`/v1/system/dictionary/add`, dto);
  }

  public static async findByName(name: string): Promise<FMPayResponse<FMPayDictionaryModel[]>> {
    return await HttpClient.Get(`/v1/system/dictionary/name/${encodeURIComponent(name)}`);
  }
}
