import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/group/Service";
import { CreateDTO, FMPayGroupModel, PaginationQuery, UpdateDTO } from "fp/modules/group/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const groupActive = createAsyncThunk<FMPayGroupModel, string>(
  "group/active",
  async (id: string) => {
    const resp = await Service.active(id);
    return resp.data;
  }
);

export const groupDisable = createAsyncThunk<FMPayGroupModel, string>(
  "group/disable",
  async (id: string) => {
    const resp = await Service.disable(id);
    return resp.data;
  }
);

export const groupUpdateAlias = createAsyncThunk<FMPayGroupModel, UpdateDTO>(
  "group/update.alias",
  async (dto: UpdateDTO) => {
    const resp = await Service.update(dto.id, dto.alias);
    return resp.data;
  }
);

export const groupPagination = createAsyncThunk<
  FMPayPagination<FMPayGroupModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("group/pagination", async (query) => {
  const resp = await Service.pagination(query);
  return resp;
});

export const groupCreate = createAsyncThunk<FMPayGroupModel, CreateDTO>(
  "group/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface GroupState {
  model: FMPayGroupModel | null;
  models: FMPayPagination<FMPayGroupModel>;
  loading: {
    deleted: string[];
    active: string;
    disable: string;
    pagination: boolean;
    updateAlias: string;
    create: boolean;
  };
}

const InitialState: GroupState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    deleted: [],
    pagination: false,
    active: "",
    disable: "",
    updateAlias: "",
    create: false
  }
};

export const groupSlice = createSlice({
  name: "group",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(groupActive.pending, (state, action) => {
      state.loading.active = action.meta.arg;
    });
    builder.addCase(groupActive.rejected, (state) => {
      state.loading.active = "";
    });
    builder.addCase(groupActive.fulfilled, (state) => {
      state.loading.active = "";
    });

    builder.addCase(groupDisable.pending, (state, action) => {
      state.loading.disable = action.meta.arg;
    });
    builder.addCase(groupDisable.rejected, (state) => {
      state.loading.disable = "";
    });
    builder.addCase(groupDisable.fulfilled, (state) => {
      state.loading.disable = "";
    });

    builder.addCase(groupUpdateAlias.pending, (state, action) => {
      state.loading.updateAlias = action.meta.arg.id;
    });
    builder.addCase(groupUpdateAlias.rejected, (state) => {
      state.loading.updateAlias = "";
    });
    builder.addCase(groupUpdateAlias.fulfilled, (state, action) => {
      state.loading.updateAlias = "";
      state.model = action.payload;
    });

    builder.addCase(groupPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(groupPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(groupPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(groupCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(groupCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(groupCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default groupSlice.reducer;
