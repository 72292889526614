import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayGroupAccountBookNoteModel,
  PaginationQuery
} from "fp/modules/group-account-book-note/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayGroupAccountBookNoteModel>> {
    return await HttpClient.Get(`/v1/group/account/book/note/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/group/account/book/note/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayGroupAccountBookNoteModel>> {
    return await HttpClient.Post(`/v1/group/account/book/note/pagination`, query);
  }
  public static async update(
    model: FMPayGroupAccountBookNoteModel
  ): Promise<FMPayResponse<FMPayGroupAccountBookNoteModel>> {
    return await HttpClient.Post(`/v1/group/account/book/note/update`, model);
  }

  public static async create(
    dto: CreateDTO
  ): Promise<FMPayResponse<FMPayGroupAccountBookNoteModel>> {
    return await HttpClient.Post(`/v1/group/account/book/note/add`, dto);
  }
}
