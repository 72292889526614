import {
  HttpResponse,
  FMPayBankModel,
  AccountBookModel,
  MarketingAccountModel,
  FMPayUserModel,
  Pagination,
  AccountBookItemsModel,
  AccountBookListOriginalsModel,
  TokenModel,
  WeChatContactModel,
  WeChatMessageModel,
  WithoutBaseModel,
  AccountBookContrastModel,
  PercentageItemModel,
  IdentificationCodeModel,
  IdentificationCodeUsageModel,
  WorkShiftModel,
  CapitalInjectionBookModel,
  AccountAbnormalBookModel,
  AccountWarnModel,
  PaymentErrorRecordModel,
  SettleUpRecordModel,
  SettleUpRecordMateModel,
  FMPayPaymentOrderModel,
  PaymentBankModel,
  WithoutFMPayBaseModel,
  FMPaySecurityCodeModel,
  FMPayResponse,
  FMPayResponsePagination,
  SecurityCodeStatus,
  FMPayGroupModel,
  FMPayPagination,
  FMPayGroupAccountBookModel,
  FMPayPaginationTimeRangeQuery,
  SendSMSModel,
  FMPayPaginationQuery,
  MoniepointTaskParams,
  OrderPaginationQuery,
  FMPayCompanyModel,
  FMPayMoniepointBankModel,
  PaymentOrderAddDTO,
  FMPayGroupAccountBookAggregationDTO,
  MoniepointPaginationQuery,
  FMPaymentInfo,
  FMPayOrderUpdateStatusDTO,
  FMPayGroupPaginationQuery,
  FMPaySettlementMethod
} from "fp/store/type";
import { HttpClient } from "fp/store/HttpClient";
import { SendSMSDTO } from "fp/store/dto/sms/SendSMSDTO";
import {
  BulkSMSBalance,
  BulkSMSMessageReportQuery,
  BulkSMSMessageReportResponse,
  SMSConsumeCodeModel
} from "fp/components/sms/BulkSMSStatus";

export class Services {
  public static readonly FMPayMoniepointBankModel = {
    async create(
      dto: WithoutFMPayBaseModel<FMPayMoniepointBankModel>
    ): Promise<FMPayResponse<FMPayMoniepointBankModel>> {
      return await HttpClient.Post(`/v1/moniepoint/bank/add`, dto);
    },

    async update(
      model: FMPayMoniepointBankModel
    ): Promise<FMPayResponse<FMPayMoniepointBankModel>> {
      return await HttpClient.Post(`/v1/moniepoint/bank/update`, model);
    },

    async pagination(
      query: FMPayPaginationQuery<string>
    ): Promise<FMPayResponsePagination<FMPayMoniepointBankModel>> {
      return await HttpClient.Post(`/v1/moniepoint/bank/pagination`, query);
    },

    async active(id: string): Promise<FMPayResponse<FMPaymentInfo>> {
      return await HttpClient.Post(`/v1/moniepoint/bank/active/${id}`, {});
    },

    async disable(info: FMPaymentInfo): Promise<FMPayResponse<FMPaymentInfo>> {
      return await HttpClient.Post(`/v1/moniepoint/bank/disable`, info);
    },

    async paymentInfo(): Promise<FMPayResponse<FMPaymentInfo[]>> {
      return await HttpClient.Get(`/v1/moniepoint/bank/payment/info`);
    }
  };

  public static readonly FMPayCompany = {
    async create(name: string): Promise<FMPayResponse<FMPayCompanyModel>> {
      return await HttpClient.Post(`/v1/company/add`, { name });
    },

    async pagination(
      query: FMPayPaginationQuery<string>
    ): Promise<FMPayResponsePagination<FMPayCompanyModel>> {
      return await HttpClient.Post(`/v1/company/pagination`, query);
    }
  };
  // User
  public static readonly User = {
    async create(
      model: WithoutFMPayBaseModel<FMPayUserModel>
    ): Promise<FMPayResponse<FMPayUserModel>> {
      return await HttpClient.Post("/v1/user/add", model);
    },
    async update(model: FMPayUserModel): Promise<FMPayResponse<FMPayUserModel>> {
      return await HttpClient.Post("/v1/user/update", model);
    },
    async login(
      username: string,
      password: string,
      securityCode: string
    ): Promise<FMPayResponse<FMPayUserModel>> {
      return await HttpClient.Post("/v1/user/login", { username, password, securityCode });
    },
    async logout(): Promise<FMPayResponse<null>> {
      return await HttpClient.Get("/v1/user/logout");
    },
    async pagination(
      query: FMPayPaginationQuery<string>
    ): Promise<FMPayResponsePagination<FMPayUserModel>> {
      return await HttpClient.Post("/v1/user/pagination", query);
    },
    async isLogin(): Promise<FMPayResponse<FMPayUserModel>> {
      return await HttpClient.Get("/v1/user/is/login");
    }
  };

  // Bank
  public static readonly Bank = {
    async create(name: string, account: string): Promise<HttpResponse<FMPayBankModel>> {
      return await HttpClient.Post("/m/bank/create", { name, account });
    },
    async items(page_size: number, page_num: number): Promise<Pagination<FMPayBankModel>> {
      return await HttpClient.Post("/m/bank/items", { page_num, page_size });
    },
    async getOrCreate(name: string, account: string): Promise<HttpResponse<FMPayBankModel>> {
      return await HttpClient.Post("/m/bank/get/or/create", { name, account });
    }
  };

  // WorkShift
  public static readonly WorkShift = {
    async create(
      begin_time: number,
      end_time: number,
      work_date: number
    ): Promise<HttpResponse<WorkShiftModel>> {
      return await HttpClient.Post("/m/work/shift/create", { begin_time, end_time, work_date });
    },
    async itemsByDay(
      year: number,
      month: number,
      day: number
    ): Promise<HttpResponse<WorkShiftModel[]>> {
      return await HttpClient.Get(`/m/work/shift/items/by/day/${year}/${month}/${day}`);
    }
  };

  // CapitalInjectionBook
  public static readonly CapitalInjectionBook = {
    async create(
      work_shift_id: number,
      original_value: number,
      target_value: number,
      exchange_rate: number
    ): Promise<HttpResponse<CapitalInjectionBookModel>> {
      return await HttpClient.Post("/m/capital/injection/create", {
        work_shift_id,
        original_value,
        target_value,
        exchange_rate
      });
    },

    async itemsByWorkShift(
      work_shift_id: number
    ): Promise<HttpResponse<CapitalInjectionBookModel[]>> {
      return await HttpClient.Get(`/m/capital/injection/items/by/work/shift/${work_shift_id}`);
    },

    async getExchangeRateByWorkShift(
      work_shift_id: number
    ): Promise<HttpResponse<Array<WorkShiftModel & { exchange_rate_avg: number }>>> {
      return await HttpClient.Get(
        `/m/capital/injection/get/exchange/rate/by/work/shift/${work_shift_id}`
      );
    }
  };

  // AccountAbnormalBook
  public static readonly AccountAbnormalBook = {
    async create(content: string): Promise<HttpResponse<AccountAbnormalBookModel>> {
      return await HttpClient.Post("/m/account/abnormal/create", { content });
    },
    async update(
      id: number,
      data: Partial<WithoutBaseModel<AccountAbnormalBookModel>>
    ): Promise<HttpResponse<AccountAbnormalBookModel>> {
      return await HttpClient.Post(`/m/account/abnormal/update/${id}`, data);
    },
    async items(
      page_num: number,
      page_size: number
    ): Promise<Pagination<AccountAbnormalBookModel>> {
      return await HttpClient.Post(`/m/account/abnormal/items`, { page_num, page_size });
    }
  };

  // AccountWarn
  public static readonly AccountWarn = {
    async create(
      amount_of_money: number,
      repeat: number,
      delay: number
    ): Promise<HttpResponse<AccountWarnModel>> {
      const model: AccountWarnModel = {
        id: -1,
        create_time: Date.now(),
        update_time: Date.now(),
        created_by: -1,
        amount_of_money,
        rate: [],
        repeat,
        delay
      };
      window.localStorage.setItem("model.account.warn", JSON.stringify(model));
      return { data: model, success: true, message: "" };
      // return await HttpClient.Post("/m/account/warn/create", { amount_of_money, repeat });
    },
    async get_by_user(): Promise<HttpResponse<AccountWarnModel | null>> {
      const content: string | null = window.localStorage.getItem("model.account.warn");
      const result: HttpResponse<AccountWarnModel | null> = {
        data: null,
        success: true,
        message: ""
      };
      if (!content) {
        return result;
      }

      result.data = JSON.parse(content) as AccountWarnModel;
      return result;
      // return await HttpClient.Get("/m/account/warn/get/by/user");
    }
  };

  // MarketingAccount
  public static readonly MarketingAccount = {
    async create(
      name: string,
      account: string,
      percentage: number
    ): Promise<HttpResponse<MarketingAccountModel>> {
      return await HttpClient.Post("/m/marketing/account/create", { name, account, percentage });
    },
    async items(page_size: number, page_num: number): Promise<Pagination<MarketingAccountModel>> {
      return await HttpClient.Post("/m/marketing/account/items", { page_num, page_size });
    }
  };

  // AccountBook
  public static readonly AccountBook = {
    async create(
      work_shift_id: number,
      bank_id: number,
      group_id: number,
      marketing_account: number,
      value: number,
      rmb: number,
      notes: string
    ): Promise<HttpResponse<AccountBookModel>> {
      return await HttpClient.Post("/m/account/book/create", {
        work_shift_id,
        bank_id,
        group_id,
        marketing_account,
        value,
        rmb,
        notes
      });
    },
    async items(page_size: number, page_num: number): Promise<Pagination<AccountBookItemsModel>> {
      return await HttpClient.Post("/m/account/book/items", { page_num, page_size });
    },
    async items_by_day(
      year: number,
      month: number,
      day: number
    ): Promise<HttpResponse<AccountBookItemsModel[]>> {
      return await HttpClient.Get(`/m/account/book/items/by/day/${year}/${month}/${day}`);
    },
    async items_by_time(
      start_time: number,
      end_time: number
    ): Promise<HttpResponse<AccountBookItemsModel[]>> {
      return await HttpClient.Get(`/m/account/book/items/by/time/${start_time}/${end_time}`);
    },
    async remove(ids: number[]): Promise<HttpResponse<boolean>> {
      return await HttpClient.Delete("/m/account/book", { ids });
    },
    async split(account_id: number, values: number[]): Promise<HttpResponse<AccountBookModel[]>> {
      return await HttpClient.Post("/m/account/book/split", { id: account_id, values });
    },
    async merge(ids: number[]): Promise<HttpResponse<AccountBookModel[]>> {
      return await HttpClient.Post("/m/account/book/merge", { ids });
    },
    async list_originals(
      account_book_id: number
    ): Promise<HttpResponse<AccountBookListOriginalsModel[]>> {
      return await HttpClient.Get(`/m/account/book/list/originals/${account_book_id}`);
    },
    async items_by_bank(
      work_shift_id: number,
      name: string,
      account: string,
      value: number
    ): Promise<HttpResponse<FMPayBankModel[]>> {
      return await HttpClient.Post("/m/bank/items/by/bank", {
        work_shift_id,
        name,
        account,
        value
      });
    }
  };

  public static readonly FMPaySMS = {
    async send(data: SendSMSDTO): Promise<FMPayResponse<SendSMSModel>> {
      return await HttpClient.Post("/v1/sms/send/template", data);
    },
    async pagination(query: FMPayPaginationQuery): Promise<FMPayResponsePagination<SendSMSModel>> {
      return await HttpClient.Post("/v1/sms/pagination", query);
    },
    async resend(id: string): Promise<FMPayResponse<SendSMSModel>> {
      return await HttpClient.Post("/v1/sms/resend/template", { id });
    },
    async balance(): Promise<FMPayResponse<BulkSMSBalance>> {
      return await HttpClient.Get("/v1/sms/balance");
    },

    async messageReport(
      query: BulkSMSMessageReportQuery
    ): Promise<FMPayResponse<BulkSMSMessageReportResponse>> {
      return await HttpClient.Post("/v1/sms/message/report", { ...query });
    }
  };

  public static readonly SMSConsumeCodeModel = {
    async findByCode(code: string): Promise<FMPayResponse<SMSConsumeCodeModel>> {
      return await HttpClient.Post(`/v1/sms/consume/code/by/code`, { code });
    },
    async consume(code: string): Promise<FMPayResponse<SMSConsumeCodeModel>> {
      return await HttpClient.Post(`/v1/sms/consume/code/consume`, { code });
    },
    async pagination(
      query: FMPayPaginationQuery<string>
    ): Promise<FMPayResponsePagination<SMSConsumeCodeModel>> {
      return await HttpClient.Post(`/v1/sms/consume/code/pagination/by/consume/time/range`, query);
    }
  };

  public static readonly FMPayGroupAccountBookModel = {
    async create(mod: {
      groupId: string;
      amount: string;
      settlement: FMPaySettlementMethod;
      normalBankId: string;
    }): Promise<FMPayResponse<FMPayGroupAccountBookModel>> {
      return await HttpClient.Post("/v1/group/account/book/add", mod);
    },
    async paginationByGroup(
      query: FMPayPaginationTimeRangeQuery<{ groupId: string }>
    ): Promise<FMPayResponsePagination<FMPayGroupAccountBookModel>> {
      return await HttpClient.Post("/v1/group/account/book/pagination/by/group", query);
    },

    async paginationAll(
      query: FMPayPaginationTimeRangeQuery<{}>
    ): Promise<FMPayResponsePagination<FMPayGroupAccountBookModel>> {
      return await HttpClient.Post("/v1/group/account/book/pagination", query);
    },

    async aggregation(
      query: FMPayPaginationQuery<{ workSchedulingOperationId: string }>
    ): Promise<FMPayResponsePagination<FMPayGroupAccountBookAggregationDTO>> {
      return await HttpClient.Post("/v1/group/account/book/pagination/aggregation", query);
    }
  };

  public static readonly FMPayOrder = {
    async pagination(
      query: FMPayPaginationQuery<Partial<OrderPaginationQuery>>
    ): Promise<FMPayResponsePagination<FMPayPaymentOrderModel>> {
      return await HttpClient.Post("/v1/order/pagination", query);
    },

    async paginationByGroup(
      query: FMPayPaginationTimeRangeQuery<{ groupId: string }>
    ): Promise<FMPayResponsePagination<FMPayPaymentOrderModel>> {
      return await HttpClient.Post("/v1/order/pagination/by/group", query);
    },
    async getById(id: string): Promise<FMPayResponse<FMPayPaymentOrderModel | null>> {
      return await HttpClient.Get(`/v1/order/${id}`);
    },

    async create(model: PaymentOrderAddDTO): Promise<FMPayPaymentOrderModel> {
      const { amount, ...others } = model;
      return await HttpClient.Post("/v1/order/add", {
        amount: amount.toString(),
        ...others
      });
    },

    async itemByAccount(account: string): Promise<PaymentErrorRecordModel[]> {
      return await HttpClient.Get(`/v1/order/by/account/${account}`);
    },

    async itemByCreateTimeRange(
      query: FMPayPaginationTimeRangeQuery<string>
    ): Promise<FMPayResponsePagination<FMPayPaymentOrderModel>> {
      return await HttpClient.Post(`/v1/order/pagination/by/create/time/range`, query);
    },

    async itemByPaymentRecord(): Promise<FMPayPaymentOrderModel[]> {
      return await HttpClient.Get(`/v1/order/by/payment`);
    },

    async nullifyOrder(id: string): Promise<FMPayResponse<FMPayPaymentOrderModel>> {
      return await HttpClient.Post("/v1/order/nullify", { id });
    },
    async resetStatus(id: string): Promise<FMPayResponse<FMPayPaymentOrderModel>> {
      return await HttpClient.Post("/v1/order/reset/status", { id });
    },

    async updateStatus(
      dto: FMPayOrderUpdateStatusDTO
    ): Promise<FMPayResponse<FMPayPaymentOrderModel>> {
      return await HttpClient.Post("/v1/order/update/status", dto);
    }
  };

  public static readonly FMPayBank = {
    async items(): Promise<PaymentBankModel[]> {
      return await HttpClient.Get("/v1/bank/items");
    }
  };

  public static readonly MoniepointTask = {
    async accounts(
      query: FMPayPaginationQuery<Partial<MoniepointPaginationQuery>>
    ): Promise<FMPayResponsePagination<MoniepointTaskParams>> {
      return await HttpClient.Post("/v1/mp/task/accounts", query);
    },

    async current(): Promise<FMPayResponse<string[]>> {
      return await HttpClient.Get("/v1/mp/task/current");
    },

    async active(phoneNumber: string): Promise<FMPayResponse<MoniepointTaskParams | null>> {
      return await HttpClient.Post(`/v1/mp/task/active/${phoneNumber}`);
    },
    async disable(phoneNumber: string): Promise<FMPayResponse<MoniepointTaskParams | null>> {
      return await HttpClient.Post(`/v1/mp/task/disable/${phoneNumber}`);
    }
  };

  public static readonly PaymentErrorRecord = {
    async create(
      model: WithoutBaseModel<PaymentErrorRecordModel>
    ): Promise<HttpResponse<PaymentErrorRecordModel>> {
      return await HttpClient.Post(`/m/payment/error/record/create`, model);
    },
    async item_by_time(
      day_start: number,
      day_end: number
    ): Promise<HttpResponse<PaymentErrorRecordModel[]>> {
      return await HttpClient.Get(`/m/payment/error/record/item/by/time/${day_start}/${day_end}`);
    }
  };

  public static readonly FMPaySecurityCode = {
    async create(account: string): Promise<FMPayResponse<FMPaySecurityCodeModel>> {
      return await HttpClient.Post("/v1/security/code/add", { account });
    },
    async pagination(
      current: number,
      size: number
    ): Promise<FMPayResponsePagination<FMPaySecurityCodeModel>> {
      return await HttpClient.Post("/v1/security/code/pagination", { current, size });
    },
    async paginationByStatus(
      status: SecurityCodeStatus,
      current: number,
      size: number
    ): Promise<FMPayResponsePagination<FMPaySecurityCodeModel>> {
      return await HttpClient.Post("/v1/security/code/pagination", { status, current, size });
    },
    async nullify(id: string): Promise<FMPayResponse<FMPaySecurityCodeModel>> {
      return await HttpClient.Post("/v1/security/code/nullify", { id });
    }
  };

  public static readonly FMPayGroup = {
    async create(name: string, alias: string[]): Promise<FMPayResponse<FMPayGroupModel>> {
      return await HttpClient.Post("/v1/group/add", { name, alias });
    },
    async update(id: string, alias: string[]): Promise<FMPayResponse<FMPayGroupModel>> {
      return await HttpClient.Post("/v1/group/update/alias", { id, alias });
    },
    async items(): Promise<FMPayPagination<FMPayGroupModel>> {
      return await HttpClient.Post("/v1/group/items", {});
    },
    async pagination(
      query: FMPayPaginationQuery<Partial<FMPayGroupPaginationQuery>>
    ): Promise<FMPayPagination<FMPayGroupModel>> {
      return await HttpClient.Post("/v1/group/pagination", query);
    }
  };

  public static readonly Percentage = {
    async compute(
      year: number,
      month: number,
      day: number
    ): Promise<HttpResponse<PercentageItemModel[]>> {
      return await HttpClient.Get(`/m/percentage/compute/${year}/${month}/${day}`);
    },
    async items_by_day(
      year: number,
      month: number,
      day: number
    ): Promise<HttpResponse<PercentageItemModel[]>> {
      return await HttpClient.Get(`/m/percentage/items/by/day/${year}/${month}/${day}`);
    },
    async items_by_month(
      year: number,
      month: number
    ): Promise<HttpResponse<PercentageItemModel[]>> {
      return await HttpClient.Get(`/m/percentage/items/by/month/${year}/${month}`);
    }
  };

  public static readonly IdentificationCode = {
    async create(name: string): Promise<HttpResponse<IdentificationCodeModel>> {
      return await HttpClient.Post("/m/idcode/create", { name });
    },
    async items(): Promise<HttpResponse<IdentificationCodeModel[]>> {
      return await HttpClient.Get("/m/idcode/items");
    },
    async take(expiration: number): Promise<HttpResponse<IdentificationCodeModel | null>> {
      return await HttpClient.Post("/m/idcode/take", { expiration });
    },
    async take_in_usage(): Promise<
      HttpResponse<Array<IdentificationCodeModel & { usage: IdentificationCodeUsageModel }>>
    > {
      return await HttpClient.Get("/m/idcode/take/in/usage");
    }
  };

  public static readonly SettleUpRecord = {
    async create(
      work_shift_id: number,
      group_id: number,
      value: number
    ): Promise<HttpResponse<SettleUpRecordModel>> {
      return await HttpClient.Post("/m/settleuprecord/create", { work_shift_id, group_id, value });
    },
    async items_by_query(
      work_shift_id: number,
      group_id: number
    ): Promise<Pagination<SettleUpRecordMateModel>> {
      return await HttpClient.Get(`/m/settleuprecord/items/by/query/${work_shift_id}/${group_id}`);
    },
    async items(page_num: number, page_size: number): Promise<Pagination<SettleUpRecordMateModel>> {
      return await HttpClient.Post("/m/settleuprecord/items", { page_num, page_size });
    }
  };

  public static readonly Contrast = {
    async create(
      model: WithoutBaseModel<AccountBookContrastModel>
    ): Promise<HttpResponse<AccountBookContrastModel>> {
      return await HttpClient.Post("/m/account/book/contrast/create", model);
    },
    async items(
      page_size: number,
      page_num: number
    ): Promise<Pagination<AccountBookContrastModel>> {
      return await HttpClient.Post("/m/account/book/contrast/items", {
        page_size,
        page_num
      });
    }
  };

  // Token
  public static readonly Token = {
    async create(seconds: number): Promise<HttpResponse<TokenModel>> {
      return await HttpClient.Post("/m/token/create", { seconds });
    },
    async items(page_size: number, page_num: number): Promise<Pagination<TokenModel>> {
      return await HttpClient.Post("/m/token/items", { page_num, page_size });
    },
    async deleteOne(id: number): Promise<HttpResponse<boolean>> {
      return await HttpClient.Delete(`/m/token/${id}`);
    }
  };

  // WeChat
  public static readonly WeChat = {
    Data: {
      async realod(): Promise<any> {
        return await HttpClient.Get("/wechat/info");
      }
    },
    Contact: {
      async groupItems(): Promise<HttpResponse<WeChatContactModel[]>> {
        return await HttpClient.Get("/wechat/concat/group/items");
      },

      async groupMessageByTime(
        username: string,
        start_time: number,
        end_time: number
      ): Promise<HttpResponse<WeChatMessageModel[]>> {
        if (process.env.NODE_ENV === "development") {
          return {
            data: [
              `
当前记账金额: 1784.00
+28.83*6.1=175.86#weeknkhvmv@hotmail.com----Ryan Ellen质押付款
`,
              `
当前记账金额: 0.00
-4277.5=-4277.5
`,

              `
当前记账金额: -250
-50*5=-250
`,

              `
成功清账金额: 347400
`,

              `
已成功清账: 15431.79
`,

              `
已撤回: +10*5=50
当前记账金额: 11290.29
`,

              `
已撤回: +18.25*6=109.5#feqscakhqsplifb@hotmail.com----Heloise Onions
当前记账金额: -4487.38　
`,

              `
当前账单金额: 37322.24
撤回: +10*5.3=53
`,

              `
当前记账金额: 13602.00
当前记录: 
+150*4.8=720
+170*4.8=816
+0=0
+300*5.2=1560
+130*5.2=676
+200*50.2=10040
+200*5.2=1040
-200*50.2=-10040
+200*5.2=1040
+0=0
+900*5=4500
+150*5=750
`,

              `
当前记账金额: 0.00
`,

              `
上次记账(已清账)金额: 5357.72
上次记录: 
-926=-926
+52.1*6.1=317.81#菲律宾
+111.1*6.1=677.71
+166.7*6.1=1016.87#菲律宾
+55.5*6.1=338.55#菲律宾
`,

              `
当前记账金额: -5623.96
当前记录: 
+19.34*6=116.04#pgjiomgxnl@hotmail.com----Adonis Freeman
+20.93*6=125.58#pgjiomgxnl@hotmail.com----Adonis Freeman
+52.29*6=313.74#fchctbpbsw@hotmail.com----Kenneth Charlotte
+47.82*6=286.92#lgxriwta@hotmail.com----Enoch Bryce
`,

              `
当前记账金额: 7871.31
当前记录: 
+27.35*6.4=175.04
+92.32*6.4=590.85
+112.78*6.4=721.79
+30.54*6.4=195.46
+18.51*6.4=118.46
+46.15*6.4=295.36
+46.75*6.4=299.2
+46.28*6.4=296.19
+20.36*6.4=130.3
+13.64*6.4=87.3
+28.2*6.4=180.48
+55.53*6.4=355.39
+101.79*6.4=651.46
+25.45*6.4=162.88
+28.05*6.4=179.52
+27.77*6.4=177.73
+23.41*6.4=149.82
+23.14*6.4=148.1
`,

              `
当前记录：
+200*5.1=1020
+500*5.25=2625
+100*5.25=525
+50*4.5=225
+50*4.5=225
+50*4.7=235
+25*3.3=82.5
+30*4.3=129
+50*4.7=235
+25*4.0=100
+25*2.8=70
+50*3.6=180
+50*4.8=240
+50*4.8=240
总余额：6131.5
已成功清账
`
            ].map((r, i) => ({
              CreateTime: Date.now() + i * 1000,
              IsSender: 0,
              Status: 0,
              StrContent: r,
              SubType: 1,
              TalkerId: 0,
              Type: 1,
              days: "2024-01-25",
              localId: 1
            })),
            message: "",
            success: true
          };
        }
        return await HttpClient.Get(
          `/wechat/chat/message/by/time/${username}/${start_time}/${end_time}`
        );
      },

      async groupMessage(
        username: string,
        year: number,
        month: number,
        day: number
      ): Promise<HttpResponse<WeChatMessageModel[]>> {
        // return await HttpClient.Get("/wechat/chat/message/by/day/43447777655@chatroom/2023/12/21")
        if (process.env.NODE_ENV === "development") {
          return {
            data: [
              {
                CreateTime: Date.now(),
                IsSender: 0,
                Status: 0,
                StrContent: "当前记账金额: 594\n+50*10+94=594",
                SubType: 1,
                TalkerId: 0,
                Type: 1,
                days: "2023-12-12",
                localId: 1
              }
            ],
            message: "",
            success: true
          };
        }
        return await HttpClient.Get(
          `/wechat/chat/message/by/day/${username}/${year}/${month}/${day}`
        );
      }
    }
  };
}
