import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/dictionary/Service";
import { CreateDTO, FMPayDictionaryModel, PaginationQuery } from "fp/modules/dictionary/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const dictionaryFindById = createAsyncThunk<FMPayDictionaryModel, string>(
  "dictionary/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const dictionaryFindByName = createAsyncThunk<FMPayDictionaryModel[], string>(
  "dictionary/find.by.id",
  async (name: string) => {
    const resp = await Service.findByName(name);
    return resp.data;
  }
);

export const dictionaryPagination = createAsyncThunk<
  FMPayPagination<FMPayDictionaryModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("dictionary/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const dictionaryDeleteByIds = createAsyncThunk<boolean, string[]>(
  "dictionary/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const dictionaryUpdate = createAsyncThunk<FMPayDictionaryModel, FMPayDictionaryModel>(
  "dictionary/update",
  async (model: FMPayDictionaryModel) => {
    const resp = await Service.update(model);
    return resp.data;
  }
);

export const dictionaryCreate = createAsyncThunk<FMPayDictionaryModel, CreateDTO>(
  "dictionary/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface DictionaryState {
  model: FMPayDictionaryModel | null;
  models: FMPayPagination<FMPayDictionaryModel>;
  loading: {
    findById: boolean;
    findByName: boolean;
    pagination: boolean;
    deleted: string[];
    update: string;
    create: boolean;
  };
}

const InitialState: DictionaryState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    findByName: false,
    pagination: false,
    deleted: [],
    update: "",
    create: false
  }
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(dictionaryFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(dictionaryFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(dictionaryFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(dictionaryDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(dictionaryDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(dictionaryDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(dictionaryUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(dictionaryUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(dictionaryUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(dictionaryPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(dictionaryPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(dictionaryPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(dictionaryCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(dictionaryCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(dictionaryCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default dictionarySlice.reducer;
